<template>
    <Loading v-if="loading">LOADING</Loading>
    <div v-else class="py-3">
        <div class="row mx-2 mx-lg-4 mx-xl-5">
            <div class="col-12 col-md-6 pe-md-5">
                <h4 class="text-left mx-1">Champion Rankings</h4>
                <div class="mt-3 d-flex">
                    <Select
                        :options="$asset.lol.longElos"
                        v-model="championRankingElo"
                        class="me-2"
                        width="120px"
                        z-index="2"
                    ></Select>
                    <Select
                        :options="championRankingMetrics"
                        v-model="championRankingMetric"
                        class="me-2"
                        width="120px"
                        z-index="1"
                    ></Select>
                </div>
                <Tab
                    class="mt-2"
                    :options="$store.state.winWidth < 992 ? $asset.lol.roles: $asset.lol.longRoles"
                    v-model="championRankingRole"
                />
                <div class="d-flex justify-content-between align-content-center px-2 mx-1 my-2 py-1">
                    <div class="extra-small text-muted">#</div>
                    <div class="extra-small text-muted ms-3">Champion</div>
                    <div v-if="championRankingMetric == 'tier'" class="d-flex justify-content-between align-content-center">
                        <div class="extra-small text-muted" style="width: 80px">Win Rate</div>
                        <div class="extra-small text-muted" style="width: 80px">Pick Rate</div>
                        <div class="extra-small text-muted" style="width: 50px">Tier</div>
                    </div>
                    <div v-if="championRankingMetric == 'win_rate'" class="d-flex justify-content-between align-content-center">
                        <div class="extra-small text-muted" style="width: 80px">Win Rate</div>
                        <div class="extra-small text-muted" style="width: 80px">Pick Rate</div>
                        <div class="extra-small text-muted" style="width: 50px">Tier</div>
                    </div>
                    <div v-if="championRankingMetric == 'pick_rate'" class="d-flex justify-content-between align-content-center">
                        <div class="extra-small text-muted" style="width: 80px">Pick Rate</div>
                        <div class="extra-small text-muted" style="width: 80px">Win Rate</div>
                        <div class="extra-small text-muted" style="width: 50px">Tier</div>
                    </div>
                    <div v-if="championRankingMetric == 'ban_rate'" class="d-flex justify-content-between align-content-center">
                        <div class="extra-small text-muted" style="width: 80px">Ban Rate</div>
                        <div class="extra-small text-muted" style="width: 80px">Win Rate</div>
                        <div class="extra-small text-muted" style="width: 50px">Tier</div>
                    </div>
                </div>
                <div class="champ-ranking-container">
                    <div
                        v-for="(champ, i) in sortRanking(summary[championRankingElo][championRankingRole], championRankingMetric)"
                        :key="'championranking' + champ.id"
                        class="d-flex justify-content-between align-content-center px-2 mx-1"
                    >
                        <div class="text-muted fst-italic my-auto">{{i + 1}}</div>
                        <div class="ms-3 d-flex justify-content-center">
                            <Champion
                                :gid="champ.id"
                                :r-size="[32, 36, 40]"
                                class="my-1"
                                :elo="championRankingElo"
                                :role="championRankingRole"
                                :link="true"
                            />
                            <div class="my-auto small d-none d-sm-inline-block d-md-none d-lg-inline-block" style="width: 130px">{{$asset.lol.champkeys[champ.id].name}}</div>
                        </div>
                        <div v-if="championRankingMetric == 'tier'" class="d-flex justify-content-between align-content-center">
                            <div class="my-auto small" style="width: 80px">{{$math.round(champ.win_rate * 100, 100)}}%</div>
                            <div class="my-auto small" style="width: 80px">{{$math.round(champ.pick_rate * 100, 100)}}%</div>
                            <div class="my-auto small" style="width: 50px"><Tier :value="champ.tier" /></div>
                        </div>
                        <div v-if="championRankingMetric == 'win_rate'" class="d-flex justify-content-between align-content-center">
                            <div class="my-auto small" style="width: 80px">{{$math.round(champ.win_rate * 100, 100)}}%</div>
                            <div class="my-auto small" style="width: 80px">{{$math.round(champ.pick_rate * 100, 100)}}%</div>
                            <div class="my-auto small" style="width: 50px"><Tier :value="champ.tier" /></div>
                        </div>
                        <div v-if="championRankingMetric == 'pick_rate'" class="d-flex justify-content-between align-content-center">
                            <div class="my-auto small" style="width: 80px">{{$math.round(champ.pick_rate * 100, 100)}}%</div>
                            <div class="my-auto small" style="width: 80px">{{$math.round(champ.win_rate * 100, 100)}}%</div>
                            <div class="my-auto small" style="width: 50px"><Tier :value="champ.tier" /></div>
                        </div>
                        <div v-if="championRankingMetric == 'ban_rate'" class="d-flex justify-content-between align-content-center">
                            <div class="my-auto small" style="width: 80px">{{$math.round(champ.ban_rate * 100, 100)}}%</div>
                            <div class="my-auto small" style="width: 80px">{{$math.round(champ.win_rate * 100, 100)}}%</div>
                            <div class="my-auto small" style="width: 50px"><Tier :value="champ.tier" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <VenatusAd class="mt-3" v-if="$store.state.winWidth < 768" :height="100" />
                <div class="mt-4 mt-md-0">
                    <h4 class="text-left mx-1">Champion Search</h4>
                    <div class="mt-3 d-flex">
                        <Input
                            placeholder="Search Champion + ELO"
                            width="calc(100% - 85px)"
                            :display-img="championSearchImgUrl"
                            :border-radiuses="['20px', 0, 0, '20px']"
                            v-model="championSearchName"
                        ></Input>
                        <Select
                            :options="$asset.lol.longElos"
                            v-model="championSearchElo"
                            :border-radiuses="[0, '20px', '20px', 0]"
                            width='120px'
                            z-index="1"
                        ></Select>
                    </div>
                    <div class="champ-search-container align-content-start mt-4 d-flex flex-wrap">
                        <Champion
                            v-for="champ in filteredChampions"
                            :key="'search' + champ[0]"
                            :gkey="champ[0]"
                            :r-size="[32, 36, 40]"
                            class="m-1"
                            :elo="championSearchElo"
                            :link="true"
                        />
                    </div>
                </div>
                <div class="d-block">
                    <h4 class="text-left mt-3 mt-md-4 pt-3 mx-1">Champion Rotation</h4>
                    <div class="d-flex mt-3 mt-md-4 champ-rotation-container">
                        <Champion
                            v-for="champid in rotation"
                            :key="'rotation' + champid"
                            :gid="champid"
                            :r-size="[32, 36, 40]"
                            class="m-1"
                            :elo="championSearchElo"
                            :link="true"
                        />
                    </div>
                </div>
                <VenatusAd class="mt-3" v-if="$store.state.winWidth >= 768" :height="100" />
            </div>
        </div>
        <!-- <Tier /> -->
    </div>
</template>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";

h4 {
    text-align: left;
}

.champ-search-container {
    height: 22vh;
    overflow-y: scroll;
}

.champ-rotation-container {
    height: 70px;
    overflow-x: scroll;
}

.champ-ranking-container {
    height: 50vh;
    overflow-y: scroll;
}

@include media-breakpoints-down($md) {
    .champ-ranking-container {
        height: 49vh;
        overflow-y: none;
    }

    .champ-rotation-container {
        height: 50px;
        overflow-x: scroll;
    }

    .champ-search-container {
        height: 100px;
        overflow-y: none;
        // overflow-x: scroll;
    }
}
</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import Champion from '@/components/lol/Champion.vue'


interface ChampionSummary {
    id: number
    key: string
    win_rate: number
    ban_rate: number
    pick_rate: number
    tier: number
}

@Options({components: {Champion}})
export default class LolChampions extends Vue {

    rotation: Array<number> = []
    summary: Record<string, Record<string, Array<ChampionSummary>>> = {}
    loading = true

    championSearchName = ""
    championSearchElo = null
    championSearchQualId: string | null = null
    filteredChampions: Array<[string, any]> = []

    championRankingElo = null
    championRankingRole = null
    championRankingMetric = "tier"

    championRankingMetrics = [
        {title: "Power Tier", value: "tier"},
        {title: "Pick Rate", value: "pick_rate"},
        {title: "Win Rate", value: "win_rate"},
        {title: "Ban Rate", value: "ban_rate"},
    ]

    @Watch('championSearchName')
    championSearchNameChanged (after: string): void {
        let id = this.$asset.lol.champkeys[after]?.id
        if (id == undefined)
            id = null
        this.championSearchQualId = id
        this.filteredChampions = this.filterChampions(after)
    }

    @Watch('championRankingRole')
    championRankingRoleChanged (after: string): void {
        this.$stge.loc['lol:championRankingRole'] = after
    }

    @Watch('championSearchElo')
    championSearchEloChanged (after: string): void {
        this.$stge.loc['lol:championSearchElo'] = after
    }

    @Watch('championRankingElo')
    championRankingEloChanged (after: string): void {
        this.$stge.loc['lol:championRankingElo'] = after
    }

    get championSearchImgUrl (): string | null {
        if (this.championSearchQualId == null)
            return null
        return this.$cdn.url('lol', ['champions', this.championSearchQualId], 'png')
    }

    async created (): Promise<void> {
        this.championRankingRole = this.$route.query.role?.toString() || this.$stge.loc['lol:championRankingRole'] || "middle"
        this.championSearchElo = this.$route.query.elo?.toString() || this.$stge.loc['lol:championSearchElo'] || "mid"
        this.championRankingElo = this.$route.query.elo?.toString() || this.$stge.loc['lol:championRankingElo'] || "mid"
        const rotationPromise = this.$cdn.get<Array<number>>('lol', 'analytics/champion-rotation', 'json')
        const summaryPromise = this.$cdn.get<Record<string, Record<string, Array<ChampionSummary>>>>('lol', 'analytics/champion-summary', 'json')
        const promises = await Promise.all([rotationPromise, summaryPromise])
        this.rotation = promises[0]
        this.summary = promises[1]
        this.$meta.use({
            title: `Champions Tier lists, Builds, Runes, Stats | StatHub.gg LoL`,
            og: {
                image: "https://cdn-stathub.iann838.com/lol/static/home-square.jpg",
                description: `Most up to date builds, runes, skill orders and statistics for all League of Legends champions in high, mid and low elo.`
            }
        })
        this.loading = false
        this.filteredChampions = this.filterChampions('')
    }

    filterChampions(nameS: string): Array<[string, any]> {
        const filtered: Array<[string, any]> = []
        const name = nameS.toLowerCase()
        for (const [k, v] of Object.entries(this.$asset.lol.champmainroles)) {
            if (!isNaN(Number(k)))
                continue
            if (k.slice(0, name.length).toLowerCase() == name)
                filtered.push([k, v])
        }
        return filtered
    }

    sortRanking (arr: Array<any>, prop: string): Array<any> {
        if (prop == 'tier')
            return arr.sort((a: any, b: any) => b[prop + '_point'] - a[prop+ '_point'])
        return arr.sort((a: any, b: any) => b[prop] - a[prop])
    }

}
</script>


import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import Champion from '@/components/lol/Champion.vue'


interface ChampionSummary {
    id: number
    key: string
    win_rate: number
    ban_rate: number
    pick_rate: number
    tier: number
}

@Options({components: {Champion}})
export default class LolChampions extends Vue {

    rotation: Array<number> = []
    summary: Record<string, Record<string, Array<ChampionSummary>>> = {}
    loading = true

    championSearchName = ""
    championSearchElo = null
    championSearchQualId: string | null = null
    filteredChampions: Array<[string, any]> = []

    championRankingElo = null
    championRankingRole = null
    championRankingMetric = "tier"

    championRankingMetrics = [
        {title: "Power Tier", value: "tier"},
        {title: "Pick Rate", value: "pick_rate"},
        {title: "Win Rate", value: "win_rate"},
        {title: "Ban Rate", value: "ban_rate"},
    ]

    @Watch('championSearchName')
    championSearchNameChanged (after: string): void {
        let id = this.$asset.lol.champkeys[after]?.id
        if (id == undefined)
            id = null
        this.championSearchQualId = id
        this.filteredChampions = this.filterChampions(after)
    }

    @Watch('championRankingRole')
    championRankingRoleChanged (after: string): void {
        this.$stge.loc['lol:championRankingRole'] = after
    }

    @Watch('championSearchElo')
    championSearchEloChanged (after: string): void {
        this.$stge.loc['lol:championSearchElo'] = after
    }

    @Watch('championRankingElo')
    championRankingEloChanged (after: string): void {
        this.$stge.loc['lol:championRankingElo'] = after
    }

    get championSearchImgUrl (): string | null {
        if (this.championSearchQualId == null)
            return null
        return this.$cdn.url('lol', ['champions', this.championSearchQualId], 'png')
    }

    async created (): Promise<void> {
        this.championRankingRole = this.$route.query.role?.toString() || this.$stge.loc['lol:championRankingRole'] || "middle"
        this.championSearchElo = this.$route.query.elo?.toString() || this.$stge.loc['lol:championSearchElo'] || "mid"
        this.championRankingElo = this.$route.query.elo?.toString() || this.$stge.loc['lol:championRankingElo'] || "mid"
        const rotationPromise = this.$cdn.get<Array<number>>('lol', 'analytics/champion-rotation', 'json')
        const summaryPromise = this.$cdn.get<Record<string, Record<string, Array<ChampionSummary>>>>('lol', 'analytics/champion-summary', 'json')
        const promises = await Promise.all([rotationPromise, summaryPromise])
        this.rotation = promises[0]
        this.summary = promises[1]
        this.$meta.use({
            title: `Champions Tier lists, Builds, Runes, Stats | StatHub.gg LoL`,
            og: {
                image: "https://cdn-stathub.iann838.com/lol/static/home-square.jpg",
                description: `Most up to date builds, runes, skill orders and statistics for all League of Legends champions in high, mid and low elo.`
            }
        })
        this.loading = false
        this.filteredChampions = this.filterChampions('')
    }

    filterChampions(nameS: string): Array<[string, any]> {
        const filtered: Array<[string, any]> = []
        const name = nameS.toLowerCase()
        for (const [k, v] of Object.entries(this.$asset.lol.champmainroles)) {
            if (!isNaN(Number(k)))
                continue
            if (k.slice(0, name.length).toLowerCase() == name)
                filtered.push([k, v])
        }
        return filtered
    }

    sortRanking (arr: Array<any>, prop: string): Array<any> {
        if (prop == 'tier')
            return arr.sort((a: any, b: any) => b[prop + '_point'] - a[prop+ '_point'])
        return arr.sort((a: any, b: any) => b[prop] - a[prop])
    }

}
